import React from "react";
import classnames from "classnames";
import SbEditable from "storyblok-react";
import TextBlock from "@/components/TextBlock/text-block";
import styles from "./blockquote.module.scss";
import { BlockquoteStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: BlockquoteStoryblok;
  className?: string;
}

const Blockquote = (props: Props) => {
  const {
    blok: { content, citation },
    className,
  } = props;
  const classes = classnames(styles.blockquote, className);

  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      <TextBlock
        as="blockquote"
        className={classes}
        content={content}
        customParagraphClass={styles.blockquoteText}
        _uid=""
        component="text-block"
      >
        {citation && (
          <footer>
            <cite className="ef-text-ui">{citation}</cite>
          </footer>
        )}
      </TextBlock>
    </SbEditable>
  );
};

export default Blockquote;
